import React from 'react';
import './ExpressYourSelf.css'; // Make sure to import your CSS file

const SelfsIOComponent = () => {
  return (
    <div className="selfs-io-container">
      selfs.io - express your<span className="yellow-text">self</span>
    </div>
  );
};

export default SelfsIOComponent;
