// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selfs-io-container {
    font-size: 32px;
    font-weight: bold;
  }
  
  .yellow-text {
    color: yellow;
  }
  `, "",{"version":3,"sources":["webpack://./src/ExpressYourSelf.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,iBAAiB;EACnB;;EAEA;IACE,aAAa;EACf","sourcesContent":[".selfs-io-container {\n    font-size: 32px;\n    font-weight: bold;\n  }\n  \n  .yellow-text {\n    color: yellow;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
